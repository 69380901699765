import { PageLayout } from "@components";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import { JoinInstitutionsV2 } from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import { Demo, GetWorkDone } from ".././biopharmaceutical";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";
import classNames from "classnames";
import FeatureSummary from "@components/FeatureSummary/FeatureSummary";
import { FeatureROI } from "@assets/icons";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";

export default function CROConsultingPage() {
	const demoProps = {
		title: "Lab automation software built for every process in CRO/consulting",
		subtitle:
			"Genemod’s modern lab automation platform is trusted by the leading organizations",
		srcImg: "lab-worker-doing-test.png",
		disableBackgroundShapes: true,
		rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: classNames(
			styles.getADemoOverride,
			styles.clinicalGetADemoOverride
		),
		titleStylesOverride: styles.CRODemoTitleStylesOverride,
		subtitleStylesOverride: styles.clinicalDemoSubtitleStylesOverride,
		leftSectionOverride: styles.clinicalDemoLeftSectionOverride,
	};

	const cards: FeatureCardProps[] = [
		{
			title: "Manage all research processes in a single software platform",
			list: [
				"Laboratory information management system",
				"Electronic lab notebook",
				"AI platform",
			],
			sublist: [
				"Genemod’s LIMS offers a unified platform for managing laboratory data, including research data, sample tracking, test results, quality control, and data analysis.",
				"Efficiently store, organize, and document research data and reports. Facilitate team collaboration on a centralized platform, minimizing time spent on redundant processes.",
				"Genemod integrates ChatGPT in our electronic lab notebook, enabling report revisions at a click and providing instant information through our Genemod AI chat bot.",
			],
            sublistColor:'text-helper',
			image: (
				<StaticImage
					src={"../../../assets/solutions/eln-ai-chat.png"}
					alt="ELN AI chat"
				/>
			),
			orientation: "right",
			className: styles.featureQuickAccess,
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
		},
	];
	const featureSummaryProps = {
		title: "Genemod will dramatically shorten the process it takes for CRO/consulting phases, saving your time and budget.",
		subtitle:
			"Our Enterprise plans help companies ensure data integrity and improve collaboration institution-wide. Each subscription is a custom-built, expert-driven foundation of your lab automation program.",
		img: <FeatureROI />,
		titleStylesOverride: styles.featureSummaryTitle,
        sectionWrapper: styles.featureSummarySectionWrapper,
        contentOverride:styles.featureSummaryContentOverride,
        subtitleStyles:styles.featureSummarySubtitle
	};
    const getWorkDoneProps = {
		title: "Accelerate breakthroughs with integrated data management and traceability",
		showSquares: false,
		showCentralizedPlatformV2: true,
		showSubtitleBottom: true,
		titleWidth: 920,
		applyV4Styles: true,
        sectionWrapper:styles.getWorkSectionWrapper
	};
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "research" }}>
			<Margins className={styles.demoMargins}>
				<Demo {...demoProps} />
			</Margins>
			<Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index.toString()} />;
					})}
				</FeatureCardLayout>
			</Margins>
			<FeatureSummary {...featureSummaryProps} />
            <Margins className={styles.getWorkDoneMargins}>
				<GetWorkDone {...getWorkDoneProps} />
			</Margins>
			<LogosAndG2Section/>
			<Margins className={styles.institutionsMargins}>
				<JoinInstitutionsV2
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
				/>
			</Margins>
		</PageLayout>
	);
}
